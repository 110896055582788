import React from 'react'
import Button from './Button';

export default function Card({content, image, onClick}: any) {
  return (
    <div className="xl:transition xl:ease-in-out xl:delay-150 
    xl:hover:scale-110 2xl:transition 2xl:ease-in-out 2xl:delay-150 
    2xl:hover:scale-110 md:transition md:ease-in-out md:delay-150 
    md:hover:scale-110 duration-300 max-w-sm rounded  shadow-lg w-[287px] h-[340px] my-2 md:h-[369px]">
  <img className="w-[285px] h-[176px]" src={image} />
  <div className="px-6 py-2">
    <p className="text-gray-700 text-sm">
      {content}
    </p>
  </div>
  <div className="px-6 pt-2 text-sm">
    <Button title="Get Started" onClick={onClick}/>
  </div>
</div>
  )
}
