import React from "react";
import { Icon } from "@iconify/react";

export default function Services() {
  return (
    <div className="flex flex-col my-8" id="services">
      <h2 className="text-center text-base mt-2 mb-[24px] md:my-[24px] xl:mt-[88px] xl:mb-[24px] lg:mt-[88px] lg:mb-[24px] 2xl:mt-[88px] 2xl:mb-[24px]">
        Our Services
      </h2>
      <div className="flex justify-center pb-4 xl:pb-[75px] lg:pb-[75px] 2xl:pb-[75px]">
        <p className="w-3/4 text-sm text-[#8B919E] xl:w-[465px] xl:text-center lg:w-[465px] lg:text-center 2xl:w-[465px] 2xl:text-center 2xl:text-[16px] 2xl:leading-[24px]">
          Increase your linkability, make tagging and bookmarking easy. Reward
          inbound links{" "}
        </p>
      </div>
      <div className="flex flex-col mx-auto justify-center md:flex-row xl:flex-row lg:flex-row 2xl:flex-row">
        <div className="flex flex-col  h-[130px] w-[280px] xl:h-[180px] xl:w-[280px] justify-center mx-4 md:mx-2 hover:border hover:border-footerColor lx:mx-[22px]">
          <div className="flex justify-center">
            <Icon
              icon="gg:play-list-search"
              color="#479cff"
              className="w-[45.83px] h-[45.83px] "
            />
          </div>
          <p className="text-center text-sm">Service information 1</p>
        </div>
        <div className="flex flex-col  h-[130px] w-[280px] xl:h-[180px] xl:w-[280px] justify-center mx-4 hover:border hover:border-footerColor lx:mx-[22px]">
          <div className="flex justify-center pb-4">
            <Icon
              icon="ph:instagram-logo-light"
              color="#479cff"
              className="w-[45.83px] h-[45.83px] "
            />
          </div>
          <p className="text-center">Service information 2</p>
        </div>
        <div className="flex flex-col  h-[130px] w-[280px] xl:h-[180px] xl:w-[280px] justify-center mx-4 hover:border hover:border-footerColor lx:mx-[22px]">
          <div className="flex justify-center pb-4">
            <Icon
              icon="el:pencil"
              color="#479cff"
              className="w-[45.83px] h-[45.83px]"
            />
          </div>
          <p className="text-center">Service information 1</p>
        </div>
      </div>
    </div>
  );
}
