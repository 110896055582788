import React from 'react'

export default function Button({title,onClick}: any) {
  return (
    <div>
        <button className='bg-primary w-[129px] h-[43px] text-textbase hover:ease-out' onClick={onClick}>
            {title}
        </button>
    </div>
  )
}
