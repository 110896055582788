import React from "react";

export default function Menu() {
  return (
    <div className="hidden justify-center items-center md:flex md:flex-col md:ml-[34px] md:mt-[227px] xl:flex xl:flex-col xl:ml-[34px] xl:mt-[227px] lg:flex lg:flex-col lg:ml-[64px] lg:mt-[227px]
    2xl:flex 2xl:flex-col 2xl:mt-[227px] 2xl:ml-[34px]">
      <div className="h-[111px] border-l border-[#9A9EA8] flex items-center justify-center "></div>
      <ul className="flex flex-col items-center justify-center space-y-8 w-max text-[#777777]">
        <li className="hover:text-primary hover:underline">
          <a href="/">Home</a>
        </li>
        <li className="hover:text-primary hover:underline">
          <a href="#getStarted">Get Started</a>{" "}
        </li>
        <li className="hover:text-primary hover:underline">
          <a href="#services">Services</a>{" "}
        </li>
        <li className="hover:text-primary hover:underline">
          <a href="#partners">Partners</a>{" "}
        </li>
      </ul>
      <div className="h-[111px] border-l border-[#9A9EA8]"></div>
    </div>
  );
}
