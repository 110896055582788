import React from "react";
import Activity from "../../Assets/Activity.png";

export default function Third() {
  return (
    <div className="flex flex-col my-2 items-center justify-center md:flex-row md:my-8 xl:flex-row xl:my-8 lg:flex-row lg:my-8 lg:pl-[0px] 2xl:flex 2xl:flex-row  2xl:my-8 2xl:items-start ">
      <img src={Activity} alt="activities image" className="w-3/4 h-3/4 xl:ml-0 mr-4 xl:w-[753px] xl:h-[470px] 2xl:w-[753px] 2xl:h-[470px] md:w-[503px] md:h-[370px] 2xl:ml-[20%]" />
      <div className="flex flex-col items-center  pt-4 pl-[0px] xl:pt-[73px] lg:pt-[73px] mx-0 w-full 2xl:items-start">
        <p className="text-[20px] w-3/4 leading-[20px] mb-2 xl:w-[432px] xl:leading-[54px] xl:mb-[25px] xl:text-4xl 2xl:text-[40px]
        2xl:leading-[54px]">Improve Performance and Growth your Business</p>
        {/* <div></div> */}
        <p className="text-sm text-[#8B919E] w-[310px] mt-[25px] md:w-[413px] xl:w-[413px] lg:w-[300px] 2xl:w-[413px] 2xl:ml-2 2xl:text-[16px] 2xl:leading-[24px]">It's a theory of psychology that prioritizes the most fundamental human needs (​like air, water, and physical safety) over more advanced needs.</p>
        <p className="text-primary text-sm mt-[26px] font-[500]">100% satisfaction guaranty</p>
      </div>
    </div>
  );
}
