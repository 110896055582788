import React, { useState } from "react";

export default function Drop() {
  const [dropdown, setDropdown] = useState(true);

  return (
    <div>
      <button
        onClick={() => setDropdown(!dropdown)}
        id="dropdownDefault"
        data-dropdown-toggle="dropdown"
        className="text-white bg-primary w-max  font-small  text-sm px-2 py-2 text-center inline-flex items-center"
        type="button"
      >
        Get Started{" "}
        <svg
          className="ml-2 w-4 h-4"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>
      <div
        id="dropdown"
        className={`${
          dropdown ? "hidden" : ""
        } absolute z-10 w-max bg-white border border-textbase shadow-xl rounded divide-y divide-gray-100`}
      >
        <ul className="py-1 text-sm " aria-labelledby="dropdownDefault">
          <li>           
            <button
              className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={()=> {
                 const redirect_url = 'https://account.konvey.rw/?role=' + encodeURIComponent('advertiser');
                 window.location.href = redirect_url
              }}
            >
              I want to advertise
            </button>
          </li>
          <li>
            <button className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
             onClick={()=> {
              const redirect_url = 'https://account.konvey.rw/?role=' + encodeURIComponent('publisher');
              window.location.href = redirect_url
           }}
            >
              I am a publisher
            </button>
          </li>
          <li>
            <button className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              I want to watch ads
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}
