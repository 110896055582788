import React from "react";
import Right_Img from "../../Assets/Right-hero.png";
import Button from "./Button";

export default function Hero() {
  return (
    <div>
      <div className="flex justify-between  mt-4  xl:flex xl:justify-between  xl:mt-[151px] xl:ml-[64px] lg:flex lg:justify-between  lg:mt-[151px] lg:ml-[4px]  
       2xl:flex 2xl:justify-end  2xl:mt-[151px] 2xl:ml-[27px] ">
        <div className="flex flex-col flex-wrap pt-4 xl:pt-[104px] lg:pt-[104px]">
          <p className="text-primary text-[14px] font-sans font-[400] ">
            Konvey Ads Platfoarm
          </p>
          <p
            className="uppercase w-3/4 h-[132px] pt-[4px] font-extrabold
          text-[32px] leading-[110%] text-[#3F4352] xl:text-[44px] xl:w-[373px] xl:h-[132px] xl:pt-[21px] 2xl:text-[44px] 2xl:w-[373px] 2xl:h-[132px] 2xl:pt-[21px] "
          >
            Let the world notice You!
          </p>
          <div>
            <img
              src={Right_Img}
              alt="pexel_image"
              className="w-full h-3/4 md:w-[553.78px] md:h-[400px] xl:w-[753.78px] xl:h-[489px] xl:hidden 2xl:hidden lg:hidden"
            />
          </div>
          <p className="text-[#777777] w-full leading-[24px] xl:mb-[25px] xl:w-[439px] xl:mt-[13px] lg:mb-[25px] lg:w-[300px] lg:mt-[13px] 2xl:mb-[25px] 2xl:w-[439px] 2xl:mt-[13px] md:w-[500px] md:mt-4 md:mb-4 ">
            We examine the top of funnel to figure out how to make people to a
            subscribe newsletter then turn prospects into leads.
          </p>
          <a href="#getStarted">
            <Button title="Get Started" />
          </a>
        </div>
        <div >
          <img
            src={Right_Img}
            alt="pexel_image"
            className="w-full hidden h-3/4 md:w-[753.78px] md:h-[489px] xl:w-[753.78px] xl:h-[489px] xl:block lg:block lg:w-[753.78px] lg:h-[489px] 2xl:w-[853.78px] 2xl:h-[589px] 2xl:block"
          />
        </div>
      </div>
    </div>
  );
}
