import React from 'react';
import Header from '../components/HomeComponents/Header';
import Hero from '../components/HomeComponents/Hero';
import Cards from '../components/HomeComponents/Cards';
import Third from '../components/HomeComponents/Third';
import Services from '../components/HomeComponents/Services';
import Partners from '../components/HomeComponents/Partners';
import Footer from '../components/HomeComponents/Footer';
import Menu from '../components/HomeComponents/Menu';
import Topmenu from "../components/HomeComponents/Topmenu";

export default function Home() {
  return (
    <div className='font-nunito bg-[#F8F9FA]'>
      <div className='flex flex-col'>
        <Topmenu/>
        <Header/>
      </div>
        <div className='flex flex-row 2xl:justify-center lg:space-x-16 space-x-8 '>
        <Menu/>
        <Hero/>
        </div>
        <Cards/>
        <Third/>
        <Services/>
        <Partners />
        <Footer/>


    </div>
  )
}
