import { channel } from 'diagnostics_channel'
import React from 'react'
import ESPRIT from '../../Assets/ESPRIT.svg'
import Union from '../../Assets/Union.svg'
import Accueil from '../../Assets/Accueil.svg'
import Channel from '../../Assets/Channel.svg'

export default function Partners() {
  return (
    <div className='flex flex-col bg-textbase mt-4 mx-2 mb-4 md:mt-[90px] xl:mt-[124px] xl:mx-[181px]  xl:mb-[252px] lg:mt-[124px] lg:mx-[281px] lg:mb-[252px] 2xl:mt-[124px]  2xl:mb-[252px] 2xl:justify-center md:w-full md:mx-primary' id="partners">
     <h2 className='text-center text-base'>Our Partners</h2>
     <div className='flex justify-center pt-[24px]'>
     <p className='text-center text-sm text-[#8B919E] w-3/4 h-[48px] xl:w-[465px] 2xl:text-[16px] 2xl:leading-[24px]'>Increase your linkability, make tagging and bookmarking easy. Reward inbound links</p>
     </div>
     <div className='flex flex-row flex-wrap mt-8 w-3/4 mx-auto xl:mt-[76px] xl:flex-nowrap 2xl:flex-nowrap 2xl:justify-center 2xl:mx-auto xl:mx-auto md:mx-auto lg:flex-nowrap lg:mx-auto lg:w-full md:flex-nowrap'>
     <div className='flex flex-row flex-wrap  mx-4 md:flex-col md:mx-16 md:items-center xl:mr-[55px] '>
       <img src={Accueil} alt="channel" className="w-[110px] mb-4 md:mb-0 xl:w-[170px] xl:h-[42px] 2xl:h-[47.44px] 2xl:w-[361px]" />
       </div>
       <div className='flex flex-row  mx-4 md:flex md:flex-col xl:mr-[55px]'>
       <img src={ESPRIT} alt="channel" className="w-[110px] mb-4 xl:w-[173px] xl:h-[39px] 2xl:h-[47.44px] 2xl:w-[381px]" />
       </div>
       <div className='flex flex-row mx-4 md:flex md:flex-col md:justify-center xl:mr-[55px] '>
       <img src={Union} alt="channel" className="w-[110px] md:mb-4 md:w-[120px] xl:w-[281.44px] xl:h-[37.44px] 2xl:h-[47.44px] 2xl:w-[361px]"/>
       </div>
       <div className='flex flex-row mx-4 md:flex md:flex-col md:mb-4 xl:w-[79px] xl:h-[53px] xl:mt-[-5px] '>
      <img src={Channel} alt="channel" className="w-[70px] md:w-[50px] xl:w-[79px] xl:h-[53px] xl:my-[0px] 2xl:h-[47.44px] 2xl:w-[381px]"/>
       </div>
     </div>
    </div>
  )
}
