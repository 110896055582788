import React from "react";
import Card from "./Card";
import HandShake from "../../Assets/HandShake.png";
import Middle from "../../Assets/Middle.png";
import Right from "../../Assets/Right.png";

export default function Cards() {
  return (
    <div
      className="flex flex-col items-center mt-[106px] mb-8 text-[#000000] md:mb-[127px]  md:w-full  md:flex-row xl:flex-row xl:ml-[279px] xl:mb-[127px] lg:flex-row lg:ml-[200px] lg:mb-[127px] 2xl:flex-row 2xl:justify-center 2xl:mb-[127px] md:mx-16"
      id="getStarted"
    >
      <Card
        content="Are You a Company, and want to share your ad?"
        image={HandShake}
        onClick={()=> {
          const redirect_url = 'https://account.konvey.rw/?role=' + encodeURIComponent('advertiser');
          window.location.href = redirect_url
       }}
        className="xl:mr-[7px] xl:ease-out xl:duration-300"
      />
      <Card
        content="Are You a Publisher, and want to work with companies?"
        image={Middle}
        className="xl:mr-[7px] xl:ease-out xl:duration-300"
        onClick={()=> {
          const redirect_url = 'https://account.konvey.rw/?role=' + encodeURIComponent('publisher');
          window.location.href = redirect_url
       }}
      />
      <Card
        content="Are You a looking for products, and win prizes watching ad?"
        image={Right}
        link="/advertisers"
      />
    </div>
  );
}
