import React from "react";
import Logo from "../../Assets/Logo.png";
import Drop from "./Drop";
import Topmenu from "./Topmenu";

function Header() {
  return (
    <div className=" mx-4  flex justify-evenly mt-[18px] md:mx-8 md:justify-between xl:mx-[280px] 
     xl:flex xl:justify-between xl:mt-[18px] xl:w-[880px] lg:mx-[200px]  lg:flex lg:justify-between lg:mt-[18px] lg:w-[880px]
     
     2xl:flex 2xl:justify-between 2xl:mx-auto 2xl:mt-4 2xl:w-[1100px]">
      <div className="xl:ml-[9px] lg:ml-[9px]">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="flex flex-row justify-between">
        <div className="text-center">
          <a href="#getStarted">
            <p className="pt-2 hidden 2xl:">Get Started</p>
          </a>
        </div>
        <Drop />
      </div>
    </div>
  );
}

export default Header;
