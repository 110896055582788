import React from "react";
import Lg from "../../Assets/Lg.png";
import { Icon } from "@iconify/react";

export default function Footer() {
  return (
    <div className="flex relative flex-col  bg-primary md:w-[920px] font-nunito py-[2%] px-[2%] 2xl:py-[4%] space-y-4  h-max  text-textbase mb-0">
      <div className="flex flex-row flex-wrap w-full mb-[77px]  justify-between">
        <div className="flex flex-col w-full xl:w-max 2xl:w-max lg:w-max">
          <div className="flex">
            <img src={Lg} alt="logo" />
          </div>
          <div>KONVEY COMPANY SLOGAN</div>
        </div>
        {/** quick links */}

        <div className="flex flex-col space-y-2 mt-8">
          <p className="text-sm font-bold">Quick links</p>
          <div className="flex flex-col space-y-1 text-sm">
            <a href="/">Home</a>
            <a href="/">Get started</a>
            <a href="/">Services</a>
            <a href="/">Partners</a>
          </div>
        </div>

        {/** contact us */}

        <div className="flex flex-col space-y-2 mt-8">
          <p className="text-sm font-bold">Contact us</p>
          <div className="flex flex-col space-y-1 text-sm">
            <div className="flex flex-row space-x-1">
              <Icon
                icon="material-symbols:call-outline-rounded"
                color="#fbfbfb"
                className="mt-1"
              />
              <p>+234 000 000 000</p>
            </div>
            <div>konvey@support.com</div>
          </div>
        </div>

        {/** Location */}

        <div className="flex flex-col space-y-2 mt-8">
          <p className="text-sm font-bold">Location</p>
          <div className="flex flex-col space-y-1 text-sm">
            <div className="flex flex-row space-x-2 ">
              <a href="https://goo.gl/maps/S25qDhgEFJhW2sHP6">
                <Icon icon="mdi:map-marker" color="#fbfbfb" className="mt-1" />
              </a>
              <p>Omega House, KN 3 Rd, 3rd floor</p>
            </div>
            <div>
              <p>Rwanda - Kigali</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <hr />
      </div>
      <div className="flex flex-row mt-[77px] items-center justify-center space-x-2">
        <p className="text-sm font-bold">Follow us:</p>
        <div className="flex flex-col">
          <div className="flex flex-row space-x-[23px]">
            <Icon icon="ic:baseline-facebook" color="#fbfbfb" width="16" height="16" />
            <Icon icon="basil:twitter-solid" color="#fbfbfb" width="16" height="16"/>
            <Icon icon="icon-park-solid:instagram-one" color="#fbfbfb" width="16" height="16"/>
            <Icon icon="material-symbols:mail-rounded" color="#fbfbfb" width="16" height="16"/>
          </div>
        </div>
      </div>

      <div className="flex text-sm flex-row items-center justify-center mt-12">
            <p>© 2023 Konvey. All rights reserved</p>
          </div>
    </div>
  );
}
